<!-- =========================================================================================
  File Name: uploads/GlbUpload.vue
  Description: GLB Upload Form
========================================================================================== -->

<template>
  <vx-card title="How should your content be positioned?">
    <form-placement
      ref="formTab4"
      :hobject="hobject"
      v-bind:addHobjectToExistingSpace="addHobjectToExistingSpace"
      v-bind:anchor="anchor"
      v-bind:space="space"
      v-bind:node="node"
      v-bind:hobjects="hobjects"
      v-bind:anchors_spaces="anchors_spaces"
      v-bind:setDefaultFloor="false"
    ></form-placement>
    <div style="justify-content: space-between" class="vx-row w-full">
      <div class="vx-col"><vs-button @click="goBack()">Cancel</vs-button></div>
      <div class="vx-col">
        <vs-button @click="AddAnchorToSpace(anchor, space)">Done</vs-button>
      </div>
    </div>
    <span v-bind:key="errorMessageUpload" v-for="errorMessageUpload in errorMessageUploads" class="text-danger"
      >{{ errorMessageUpload }}<br
    /></span>
  </vx-card>
</template>
<script>
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import FormPlacement from '@/views/hoverlay/pages/create/components/FormPlacement.vue'

export default {
  components: {
    FormPlacement,
  },
  props: {},
  data() {
    return {
      errorMessageUploads: [],
      addHobjectToExistingSpace: true,
      space: {},
      hobject: {},
      anchor: {},
      model: {
        provider: JSON.parse(localStorage.getItem('layerInfo')).name,
      },
      node: {
        angle_x: 0,
        angle_y: 0,
        angle_z: 0,
        quaternion_x: 0,
        quaternion_y: 0,
        quaternion_z: 0,
        quaternion_w: 0,
        x: 0,
        y: 0,
        z: 1.2,
        scale: 1,
      },
    }
  },
  computed: {
    isDoneButtonEnable() {
      if (this.anchor.pid || this.anchor.data) return true
      else return false
    },
    anchors_spaces() {
      return this.$store.state.hoverlay.anchors_spaces
    },
    nodes() {
      return this.$store.state.hoverlay.nodes
    },
    hobjects() {
      return this.$store.state.hoverlay.hobjects
    },
    spaces() {
      return this.$store.state.hoverlay.spaces
    },
    maxFileSize: function() {
      return HoverlayUtils.getMaxUploadFileSize()
    },
  },
  watch: {
    hobject: {
      handler: function(updatedValue) {},
      deep: true,
    },
    anchor: {
      handler: function(updatedValue) {
        if (updatedValue.data) console.log('YESSSS')
        else console.log('NOOOO')
      },
      deep: true,
    },
  },
  async created() {
    this.$eventBus.$on('anchorChanged', this.onAnchorChanged)

    if (this.$route.params.space_pid) {
      this.space = { pid: this.$route.params.space_pid }
    }
  },
  mounted() {
    console.log('LISTEN TO anchorChanged')
  },
  methods: {
    async create() {},
    goBack() {
      this.$router.go(-1)
    },
    onAnchorChanged(anchor) {
      console.log('onAnchorChanged')
      console.log(anchor)
      this.anchor = anchor
    },
    async AddAnchorToSpace(anchor, space) {
      this.errorMessageUploads = []
      if (anchor.pid || anchor.data) {
        this.$vs.loading()
        const formData = new FormData()
        Object.keys(anchor).forEach(key => formData.append(key, anchor[key]))
        if (!anchor.pid) {
          var newlyCreatedAnchor = await this.$store.dispatch('hoverlay/addAnchor', anchor)
          anchor = { pid: newlyCreatedAnchor.pid }
        }
        await this.$store.dispatch('hoverlay/addAnchorSpace', { anchor_pid: anchor.pid, space_pid: space.pid })
        this.$router.push({
          path: `/space/${space.pid}?anchor=${anchor.pid}`,
        })
        this.$vs.loading.close()
      } else {
        this.errorMessageUploads.push('You must select an anchor or create a new reference image')
      }
    },
    clearHobject() {
      this.hobject = {}
    },
  },
}
</script>

<style lang="scss"></style>
